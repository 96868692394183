@import "../variables.scss";

.cartItem {
    width: 100%;
    height: 293px;
    display: flex;
    margin-bottom: 20px;
    display: flex;
    border-bottom: 1px solid #7b7c80;
    padding: 20px 20px 23px 40px;
    &__content {
        display: flex;
        padding-bottom: 24px;
        width: 100%;
        & > img {
            height: 70%;
            margin: auto;
        }
        &--info {
            display: flex;
            width: 100%;
            flex-direction: column;
            & > div {
                display: flex;
                width: 100%;
                margin-bottom: 17px;
                svg {
                    margin-left: auto;
                    fill: $orange;
                    width: 24px;
                    height: 24px;
                }
                & > span {
                    font-size: 14px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #7b7c80;
                }
            }
            h3 {
                color: $white;
                margin-bottom: 12px;
            }
            p {
                line-height: 21px;
                color: #7b7c80;
            }
            span {
                font-size: 24px;
                line-height: 100%;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: $white;
            }
            &--colors {
                display: flex;
                flex-direction: column;
                margin-top: auto;
                bottom: 24px;
                :first-child {
                    text-transform: none;
                    letter-spacing: 0;
                    margin-bottom: 12px;
                }
                ul {
                    display: flex;
                    li {
                        width: 24px;
                        height: 24px;
                        margin-right: 16px;
                        border-radius: 50px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 575px) {
    .cartItem {
        padding: 16px;
        border: none;
        padding: 0;
        width: 100%;
        height: 160px;
        margin-top: 16px;
        div {
            img {
                width: 91.65px;
                height: 72.83px;
            }
        }
        &__content {
            padding: 0;
            &--info {
                div {
                    margin-bottom: 6px;
                    span {
                        font-size: 10px;
                    }
                    svg {
                        width: 13px;
                        height: 13px;
                    }
                }
                h3 {
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                p {
                    font-size: 14px;
                    padding: 0;
                    margin: 0;
                }
                &--colors {
                    margin-top: 12px;
                    :first-child {
                        text-transform: none;
                        letter-spacing: 0;
                        margin-bottom: 8px;
                    }
                    ul {
                        li {
                            width: 10px;
                            height: 10px;
                            margin-right: 6px;
                        }
                    }
                }
                span {
                    font-size: 10px;
                }
            }
        }
    }
}
