@font-face {
    font-family: 'BrutalType-Light';
    src: url('./BrutalType/BrutalType-Light.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BrutalType-Medium';
    src: url('./BrutalType/BrutalType-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BrutalType-Bold';
    src: url('./BrutalType/BrutalType-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
