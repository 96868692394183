.footer {
    background: #1f2023;
    height: 140px;
}

@media screen and (max-width: 575px) {
    .footer {
        height: 350px;
        ul {
            display: block;
        }
        .burgerMenu {
            display: none;
        }
        .navbar {
            img {
                margin-bottom: 24px;
            }
            flex-direction: column;
            align-items: flex-start;
            ul li {
                margin: 0;
                height: 40px;
            }
        }
    }
}
