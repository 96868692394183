@import "../variables.scss";

.navbar {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
        display: flex;
        align-items: center;
    }

    ul li {
        color: $white;
        font-size: 16px;
        margin-left: 40px;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 1024px) {
    .navbar {
        ul li {
            margin-left: 30px;
        }
    }
}

@media screen and (max-width: 992px) {
    .navbar {
        ul {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        height: 56px;
        .mainLogo {
            width: 33%;
        }
    }
}
