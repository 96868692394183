@import "../variables.scss";

.departureMaster {
    display: flex;
    flex-direction: column;
    background: #2e2f32;
    padding: 80px 0;
    background-image: url("../../assets/images/map.png");
    background-repeat: no-repeat;
    background-position: 85% 10%;
    h2 {
        margin-bottom: 40px;
    }
    span {
        font-size: 24px;
        color: $white;
    }
    ul {
        margin: 24px 0 150px;
    }
    ul li {
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 24px;
        color: $white;
        margin-bottom: 24px;
        svg {
            margin-right: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    .departureMaster {
        padding: 32px 0;
    }
}

@media screen and (max-width: 575px) {
    .departureMaster {
        background-size: 100%;
        background-position: 10% -1%;
        padding: 32px 0;
        h2 {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 320px;
        }
        ul {
            margin: 24px 0 32px;
        }
    }
}
