@import "../variables.scss";

.wholesaleOffers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        svg {
            margin-right: 24px;
        }
        h4 {
            color: $white;
            letter-spacing: 3px;
            white-space: nowrap;
        }
        div {
            display: flex;
        }
        span {
            font-size: 24px;
            color: #7b7c80;
            line-height: 100%;
            margin-left: auto;
            letter-spacing: 3px;
            text-transform: uppercase;
        }
    }
    .table {
        display: flex;
        border: 4px solid #515255;
        margin-top: 30px;
        justify-content: space-between;
        margin-bottom: 80px;
        &__block {
            text-align: center;
            width: 100%;
            &:nth-child(2) {
                border-left: 4px solid #515255;
                border-right: 4px solid #515255;
            }
            &--head {
                background: #2e2f32;
                height: 68px;
                display: flex;
                align-items: center;
                font-size: 28px;
                justify-content: center;
                color: $white;
            }
            &--body {
                display: flex;
                font-size: 28px;
                line-height: 100%;
                color: #f4f4f4;
                align-items: center;
                height: 68px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .wholesaleOffers {
        &__top {
            div {
                display: flex;
                align-items: center;
            }
            svg {
                width: 32px;
            }
            h4 {
                font-size: 28px;
            }
            span {
                font-size: 20px;
            }
        }
        .table {
            &__block {
                &--head {
                    height: 48px;
                    font-size: 20px;
                }
                &--body {
                    font-size: 20px;
                    height: 56px;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .wholesaleOffers {
        &__top {
            display: flex;
            flex-direction: column;
            div {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                align-self: flex-start;
            }
            svg {
                margin-right: 12px;
                width: 32px;
                height: 32px;
            }
            h4 {
                font-size: 20px;
                letter-spacing: 1px;
            }
            span {
                color: #7b7c80;
                font-size: 16px;
                margin: 0 auto 0 0;
            }
        }
        .table {
            flex-direction: column;
            margin-bottom: 32px;
            &__block {
                &:nth-child(2) {
                    border: none;
                }
            }
        }
    }
}
