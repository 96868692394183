.clients {
    background: #1f2023;
    padding: 80px 0;
    &__content {
        display: flex;
        flex-direction: column;
        margin: 80px 0;
        &__img {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 100px;
            img {
                width: 45%;
            }
        }
        &__bubble {
            display: flex;
            justify-content: space-between;
            div {
                background: #2e2f32;
                border-radius: 100px;
                margin-right: 16px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .clients {
        padding: 32px 0;
        &__content {
            margin: 32px 0;
        }
    }
}

@media screen and (max-width: 575px) {
    .clients {
        padding: 0;
        &__content {
            padding: 32px 0;
            margin: 32px 0;
        }
    }
}
