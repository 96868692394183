.reviews {
  padding: 80px 0;
  &__content {
    margin-top: 100px;
  }
}

@media screen and (max-width: 575px) {
  .reviews {
    padding: 32px 0;
    &__content {
      margin-top: 32px;
    }
  }
}
