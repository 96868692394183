@import '../variables.scss';

.blockName {
    h2 {
        color: $white;
        margin: 24px 0;
    }
    span {
        letter-spacing: 3px;
        text-transform: uppercase;
        font-size: 18px;
        color: #515255;
    }
    &__underblock {
        width: 120px;
        height: 5px;
        background: #f7a73a;
    }
    #howWeWork {
        color: $white;
    }
}

@media screen and (max-width: 575px) {
    .blockName {
        h2 {
            margin: 16px 0;
            font-size: 24px;
        }
        span {
            font-size: 14px;
        }
    }
}
