@import "../variables.scss";

@mixin scrollbar($size, $secondary: lighten($primary, 25%)) {
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: #1f2023;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }
}

.cart {
    max-width: 985px;
    height: 100%;
    padding: 40px 40px 0 40px;
    background: #2e2f32;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &__content {
        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                cursor: pointer;
            }
            h4 {
                color: $white;
                letter-spacing: 3px;
            }
            margin-bottom: 40px;
        }
        height: 620px;
        overflow: hidden;

        & > ul {
            overflow: scroll;
            height: 550px;
        }
        @include scrollbar(0.5em, tomato);
    }
    &__footer {
        background: #1f2023;
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 50px;
        left: 0;
        height: 240px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & > span {
            font-weight: 500;
            font-size: 24px;
            line-height: 48px;
            color: $white;
        }
        div {
            display: flex;
            margin-top: 3px;
        }
        &--links {
            display: flex;
            font-size: 24px;
            flex-direction: column;
            margin-right: 50px;
            a {
                margin-bottom: 20px;
            }
        }
        &--phones {
            display: flex;
            color: $white;
            margin-bottom: 20px;
            flex-direction: column;
            span {
                margin-bottom: 20px;
                font-size: 24px;
            }
        }
    }
}

.blackout {
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
}

@media screen and (max-width: 768px) {
    .cart {
        width: 95%;
        margin: auto;
        margin-top: 150px;
        padding: 16px;
        &__footer {
            height: 150px;
            padding: 16px;
            & > span {
                font-size: 24px;
                line-height: 34px;
            }
            div {
                margin-top: 0;
            }
            &--links {
                font-size: 16px;
                margin-right: 20px;
                a {
                    margin-bottom: 6px;
                }
            }
            &--phones {
                span {
                    margin-bottom: 6px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .cart {
        width: 95%;
        height: 500px;
        margin: auto;
        margin-top: 150px;
        padding: 16px;
        &__content {
            div {
                h4 {
                    font-size: 16px;
                }
                margin-bottom: 32px;
            }
            & > ul {
                height: 360px;
            }
        }
        &__footer {
            height: 74px;
            padding: 16px;
            & > span {
                font-size: 10px;
                line-height: 20px;
            }
            div {
                margin-top: 0;
            }
            &--links {
                font-size: 10px;
                margin-right: 20px;
                a {
                    margin-bottom: 6px;
                }
            }
            &--phones {
                span {
                    margin-bottom: 6px;
                    font-size: 10px;
                }
            }
        }
    }
}
