@import "../variables.scss";

.header {
  &__socialLinksTitle {
    background: #1f2023;
    height: 56px;
    display: flex;
    align-items: center;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__messangerLinks {
      color: #f4f4f4;
      user-select: none;
      :nth-child(1) {
        margin: 0 24px 0 16px;
      }
    }

    &__phones {
      color: $white;
      margin-left: 80px;
      span {
        margin-right: 24px;
      }
    }
  }

  &__wrapperContent {
    width: 100%;
    img {
      float: right;
    }
  }

  &__content {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    h1 {
      color: $white;
      letter-spacing: 5px;
      .colorText {
        color: $orange;
      }
    }
    .underblock {
      width: 120px;
      height: 5px;
      background: $white;
      margin: 24px 0 24px 0;
    }
    h5 {
      color: #b3b3b3;
      font-family: BrutalType-Light;
    }

    &__btnGrp {
      display: flex;
      margin-top: 32px;
    }
  }

  .arrowDown {
    margin: 20px auto;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    &__wrapperContent {
      img {
        width: 40%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    &__socialLinksTitle {
      height: 32px;
      &__messangerLinks,
      &__phones {
        display: none;
      }
    }
    h5 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    &__wrapperContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    h1 {
      font-size: 28px;
      line-height: 42px;
      letter-spacing: 3px;
    }
    &__content {
      margin-top: 16px;
      flex-direction: column;
      flex-flow: wrap;
      &__btnGrp {
        display: flex;
        margin-top: 32px;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        order: 2;
      }
    }
    h5 {
      font-size: 14px;
      line-height: 18px;
    }
    .underblock {
      margin: 16px 0 24px 0;
      order: 1;
    }
    div img {
      width: 100%;
      background-size: cover;
    }
    &__wrapperContent {
      div {
        order: 2;
      }
    }
  }
}
