@import "../variables.scss";

.itemCard {
    height: 639px;
    background: #2e2f32;
    display: flex;
    user-select: none;
    flex-direction: column;
    padding: 24px;

    img {
        margin-bottom: 24px;
    }

    svg {
        align-self: flex-end;
    }

    &__data {
        display: flex;
        flex-direction: column;
        height: 55%;
        &__inStock {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #2c9074;
            font-size: 14px;
            margin-bottom: 16px;
            span {
                display: flex;
            }
            svg {
                margin: 0 0 0 6px;
            }

            :first-child {
                text-transform: uppercase;
                color: #7b7c80;
                letter-spacing: 2px;
                font-size: 14px;
            }
        }
        h3 {
            color: $white;
            margin-bottom: 14px;
        }
        &__description {
            line-height: 21px;
            color: #7b7c80;
            margin-bottom: 6px;
        }
        &__colorsList {
            margin-top: auto;
            span {
                line-height: 21px;
                color: #7b7c80;
            }
            ul {
                display: flex;
                margin-top: 12px;
            }
            ul li {
                padding: 3%;
                border-radius: 50px;
                margin-right: 14px;
            }
        }
        &__price {
            font-size: 24px;
            line-height: 100%;
            align-self: flex-end;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: $white;
            margin-top: 32px;
        }
    }
}

@media screen and (max-width: 575px) {
    .itemCard {
        width: 100%;
        height: 100%;
        img {
            margin-bottom: 12px;
        }
        &__data {
            height: 36%;
            h3 {
                color: $white;
                margin: 16px 0;
                font-size: 22px;
            }
            &__colorsList {
                ul li {
                    width: 18px;
                    border-radius: 50px;
                    margin-right: 10px;
                    height: 18px;
                }
            }
            &__inStock {
                margin-bottom: 0;
            }
            &__price {
                margin-top: 16px;
                font-size: 16px;
            }
        }
    }
}
