@import "../variables.scss";

.catalog {
    padding: 80px 0;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    background: #1f2023;
    max-width: 1360px;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    h2 {
        color: $white;
        letter-spacing: 3px;
    }

    &__navbarCategories {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 40px;
        li {
            text-transform: uppercase;
            padding: 1% 2%;
            color: #7b7c80;
            letter-spacing: 3px;
            display: flex;
            align-items: center;
            background: #2e2f32;
            &:hover {
                background: #3d3f43;
            }
        }
        .activeCategory {
            color: $white;
            background: #1f2023;
            border: 3px solid $orange;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        &__btnGrp {
            margin: 40px 0;
            display: flex;
            margin-left: auto;
        }
    }
    .palette {
        h4 {
            color: $white;
            margin-bottom: 32px;
        }
        div {
            display: flex;
            justify-content: space-between;
        }
        text-align: center;
        margin: 100px 0 80px;
    }
}

@media screen and (max-width: 1024px) {
    .catalog {
        &__navbarCategories {
            li {
                padding: 1% 1%;
                letter-spacing: 2px;
            }
        }
    }
    .palette {
        div {
            img {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .catalog {
        padding: 32px 0px;
        width: 95%;
        &__content {
            padding: 0;
        }
        &__navbarCategories {
            display: none;
        }
        .palette {
            img {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .catalog {
        h2 {
            margin-bottom: 24px;
        }
        &__content {
            padding: 0;
            &__btnGrp {
                width: 100%;
                flex-direction: column;
                margin-top: 24px;
            }
        }
        .palette {
            margin-top: 24px;
            img {
                width: 50%;
            }
        }
    }
}
