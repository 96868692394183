.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1024px) {
    .container {
        padding: 24px;
    }
}

@media screen and (max-width: 767px) {
    .container {
        max-width: none;
    }
}
