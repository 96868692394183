@import "../variables.scss";

.aboutUs {
    background: #2e2f32;
    padding: 55px 0 100px;
    &__content {
        display: flex;
        justify-content: space-between;
        margin: 80px 0 100px;
        &__block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 242px;
            div {
                background: #1f2023;
                border-radius: 100px;
                padding: 43px;
            }
            :nth-child(2) {
                margin: 40px 0 16px;
                font-size: 20px;
                color: $white;
            }
            span {
                line-height: 24px;
                color: #7b7c80;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutUs {
        padding: 32px 0;
        &__content {
            margin: 50px 0;
        }
    }
}

@media screen and (max-width: 575px) {
    .aboutUs {
        display: flex;
        flex-direction: column;

        &__content {
            flex-direction: column;
            align-items: center;
            margin: 32px 0 0;
            &__block {
                margin-bottom: 32px;
            }
        }
    }
}
